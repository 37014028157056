.second-landing {
  background-size: cover;
  max-width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 1;
  background-color: transparent;
  .second-landing-wrapper { 
    max-width: 100%;
    height: 100%;
  }
  .second-landing-content {
    padding: 11.5rem 1rem 8rem 1rem;
  }
}


