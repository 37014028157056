

.bd-grid {
    max-width: 1024px;
    display: grid;
    grid-template-columns: 100%;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
  /*===== HEADER =====*/
  
  header {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: var(--header-height);
      padding:  2.5rem 0.25rem 2.5rem 0.25rem;
      z-index: var(--z-fixed);
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      transition: all 300ms ease-in-out;
      // box-shadow: 0 2px 4px rgba(0,0,0,.1);

    


      .header__logo{
          width: 14rem;
          justify-content: center;
          svg { 
              max-width: 100%;
              object-fit: cover;
          }
      }
      
      .header__toggle{
          font-size: 1.7rem;
          cursor: pointer;
          color: $neutral--100;
      }
    }

    .nav__img {
        width: 0;
        height: 0;
    }
    
    /*===== NAV =====*/
    @media (max-width: 768px) {
      .nav {
          position: fixed;
          top: 0;
          left: -100%;
          background-color: $navbar;
          width: 80%;
          height: 100vh;
          padding: 2rem 0;
          z-index: var(--z-fixed);
          transition: .5s;
          overflow-y: auto;
           &__img, 
           &__logo { 
              display: none;
           }
  
      }
    }
      .nav {
          .nav__content{
              display: flex;
              flex-direction: column;
              
          }
  
          .nav__orb { 
              display: flex;
              justify-content: center;
              margin-bottom: 1.5rem;
              max-width: 100%;
              height: auto;
              img { 
                  width: 125px;
              }
          }
  
          .nav__item  {
            a {
                color: $neutral--100;
                position: relative;
                display: block;
                padding: .25rem 0;
                &::after {
                    position: absolute;
                    content: "";
                    top: 100%;
                    left: 0;
                    width: 100%;
                    height: 3px;
                    background: $primary-color--300;
                    transform: scaleX(0);
                    transform-origin: right;
                    transition : all 0.2s ease-in-out;
                }
                &:hover {
                    color: $primary-color--300;
                }
                &:hover::after {
                    transform: scaleX(1);
                    transform-origin: left;
                }
            }
            
        }

          .nav__link { 
              color: $neutral--200;
      
              .nav__link:hover {
                  color: $primary-color--200;
              }
          }
  }
    /*Show menu*/
    .show {
      left: 0;
    }
    
    /*Active link*/
    .active {
      color: $neutral--00;
    }
    
    /*=== Dropdown ===*/
    .dropdown {
    
      .dropdown__link{
          display: flex;
          align-items: center;
          justify-content: space-between;
      }
      .dropdown__icon{
          font-size: 1.3rem;
          transition: .5s;
      }
    
      .dropdown__menu{
          margin: 0 0 0 1rem;
          display: none;
      }
    
      .dropdown__item{
          margin: 1rem 0;
      }
    
      &:hover > &__menu{
          display: block;
      }
      &:hover &__icon { 
          transform: rotate(180deg);
      }
    }
    
    /* ===== MEDIA QUERIES=====*/
    @media (min-width: 576px){
      .nav {
          width: 288px;
      }
  
    }
    
    @media (min-width: 768px){
      body {
          margin: 0;
      }
    
      header {
          height: calc(var(--header-height) + 1rem);
        
          .header__logo {
              
              svg {
                  z-index: -100;
                  
              }
          }
          .header__toggle {
              width: 0;
              height: 0;
              visibility: hidden;
          }
      }
    
      .nav {
          padding-top: .35rem;
          width: 100%;
          .nav__content{
              max-width: 100%;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
          }
          .nav__perfil {
              flex-direction: row;
              text-align: initial;
              margin-bottom: 0;
          }
          .nav__img { 
            max-width: 10rem;
            display: flex;
            justify-content: center;
            min-width: 100%;
          }
          .nav__orb { 
              display: none;
              opacity: 0;
              object {
                  padding: 2rem 2rem 2rem 2rem;
                  max-width: 2rem;


              }
          }
    
        .nav__list {
            display: flex;
            align-items: center;
            li {
                    margin: 1.5rem 1.5rem;
                    padding: 0 .5rem; 
                    text-align: center;
                }
                a {
                    color: $neutral--100;
                    position: relative;
                    display: block;
                    padding: .25rem 0;
                    &::after {
                        position: absolute;
                        content: "";
                        top: 100%;
                        left: 0;
                        width: 100%;
                        height: 3px;
                        background: $primary-color--300;
                        transform: scaleX(0);
                        transform-origin: right;
                        transition: transform 0.5s ease;
                    }
                    &:hover {
                        color: $primary-color--300;
                    }
                    &:hover::after {
                        transform: scaleX(1);
                        transform-origin: left;
                    }
                }
                
            }
        
    }
        
      /*Active link new color*/
      .active {
          color: $primary-color--200;
      }
    
      .dropdown {
          position: relative;
      
          .dropdown__menu{
              position: fixed;
              margin-top: .5rem;
              top: calc(var(--header-height) + .7rem);
            //   box-shadow: 0 2px 4px rgba(0,0,0,.1);
              border-radius: .15rem;
              z-index: inherit;
              background-color: transparent;
          }
          .dropdown__item{
              margin: 0;
              a {
                color: $neutral--100;
                position: relative;
                display: block;
                padding: .25rem 0;
                &::after {
                    position: absolute;
                    content: "";
                    top: 100%;
                    left: 0;
                    width: 100%;
                    height: 3px;
                    background: $primary-color--300;
                    transform: scaleX(0);
                    transform-origin: right;
                    transition: transform 0.5s ease;
                }
                &:hover {
                    color: $primary-color--300;
                }
                &:hover::after {
                    transform: scaleX(1);
                    transform-origin: left;
                }
            }

              }
          }
      }

    
    @media (min-width: 1024px) {
      .bd-grid {
          padding: 0;
          margin: 0;
          }
      }



      // Scroll Classes

      .scroll-down {
        header {
            transform: translate3d(0, -100%, 0);
        }
    }
    
    .scroll-up {
        header {
            background: $navbar;
            filter: drop-shadow(0 -10px 20px rgb(121, 120, 120)); 
            .dropdown__menu {
                background: $navbar;
            }
        }
    }
    


    // nav hover animation


    @keyframes line {
        0% {
          background-position-x: 390px;
        }
      }



