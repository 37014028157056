
$primary-font: 'Nunito', sans-serif;
$secondary-font: 'Nunito Sans', sans-serif;

$primary-color--200:  #e20000;
$primary-color--300: #c73232;
$primary-color--500:  #af0000;
$primary-color--600: #681212;
$primary-color--700:  #5c0000;

$secondary-color--200:  #FCF23B;
$secondary-color--500:  #E9E036;
$secondary-color--700:  #D5CD2C;

$third-color--200:  #FF6876;
$third-color--500:  #F45B69;
$third-color--700:  #E45360;

$fourth-color--200:  #A339C0;
$fourth-color--500:  #772D8B;
$fourth-color--700:  #612173;

$neutral--00:  #ffffff;
$neutral--100: #f0f0f0;
$neutral--200:  #c7c7c7;
$neutral--300:  #747474;
$neutral--800:  #3d3d3d;
$black: #000000;

$navbar: rgba(51, 51, 51, 0.9);


$width: 1024px;


:root { 
    --header-height: 2.3rem;
    --nav-name-font-size: 1.4rem;
    --normal-font-size: .938rem;
    --z-fixed: 100;
    @media (min-width:  768px) {
      --nav-name-font-size: 1rem;
      --normal-font-size: 1rem; 
    }
    --fs-xl: clamp(1.1rem, 4vw + 1rem, 4rem);
    --fs-lg: clamp(.9rem, 2vw + 1rem, 3.5rem);
    --fs-md: clamp(.5rem, 3vw + .3rem, 2rem);
    --fs-sm: clamp(.5rem, 3vw + 1rem, 1rem);
}
