


.form-container {
    width: 100%;
    height: 100%;
    padding: 2.25rem;
    display: flex;
    justify-content: center;
    align-items: center;

    form {
        width: 100%;
        max-width: 70rem;
        height: 100%;
       
        button {
            border: none;
          }
            label {
                padding-left: 0.55rem;
                color: $neutral--100;
            }
            input[type=text], 
            input[type=email],
            input[type=tel],
            input[type=url] {
                width: 90%;
                padding: .75rem;
                margin: .6rem;
                resize: vertical;
                background: $neutral--200;
                color: $neutral--800;
                border: 1px solid $neutral--800;
                border-radius: 4px;
                outline: none;
                &:focus {
                    border-color: $primary-color--200;
                }
            }
            input[type=submit] {
                padding: 1em 2em;
                color: $neutral--800;
                background: rgba(255, 255, 255, 0.10);
                border-radius: 40px;
                text-align: center;
                text-decoration: none;
                font-weight: medium;
                cursor: pointer;
                font-size: .8em;
                    transition:  transform .3s;
                    &:hover, &:after { 
                        box-shadow: (rgba(black, 0.45) 0px 0px 20px 0px);
                }
                }
                textarea { 
                    font-family: $secondary-font;
                    width: 95%;
                    padding: 1em 2em;
                    height: 200px;
                    margin: .6rem;
                    resize: vertical;
                    background: $neutral--200;
                    border: 1px solid $neutral--800;
                    color: $neutral--800;
                    border-radius: 4px;
                    outline: none;
                    &:focus {
                        border-color: $primary-color--200       ;
                    }
                } 
        }
    }
    
    .col-50{
        flex: 50%;
        -ms-flex: 50%;
    }
    .col-100{
        flex: 100%;
        -ms-flex: 100%;
    }
    .row {
        width: 100%;
        flex-direction: row;
        display: -ms-flexbox; /* IE10 */
        display: flex;
        margin: 0;
        
    }


    @media (max-width: 800px) {
        .row {
          flex-direction: column-reverse;
        }
      }


      // New Form styles

      .login-box {
        max-width: 65rem;
        width: 100%;
        margin: 0 auto;
        padding: 2rem;
        background: $neutral--100;
        box-sizing: border-box;
        border-radius: 10px;
        .user-box {
            position: relative;
            input {
                width: 100%;
                padding: .8rem 0;
                font-size: var(--fs-sm);
                color: $neutral--800;
                margin-bottom: 2.2rem;
                border: none;
                border-bottom: 1px solid $neutral--800;
                outline: none;
                background: transparent; 
                &:focus ~ label,
                &:valid ~ label{
                    top: -20px;
                    left: 0;
                    color: $primary-color--300;
                    font-size: 12px;
                }
            }
            label {
                position: absolute;
                top:0;
                left: 0;
                padding: 10px 0;
                font-size: var(--font-size-sm);
                color: $neutral--800;
                pointer-events: none;
                transition: .5s;
            }
            .textarea {
                resize: vertical;
                height: 50px
            }

        }
      }


      .form-split {
        display: flex;
        flex-direction: column;
      }
      
      @media (min-width: 40em) {
        .form-split {
            flex-direction: row;
        }
        .form-split > * {
            flex-basis: 100%;
        }
        .form-split > * + * {
          margin-left: 2em;
        }
      }