@import '../abstracts/variables';
// Backgrounds

.bg-landing { 
  background-color: transparent;
  background-size: cover;
  max-width: 100%;
  padding: 1rem 1rem 1rem;
  min-height: 100vh;
  height: 100vh;
  margin: 0;
}

// Background Diamonds 
  
.d-1 { 
    position: absolute;
    transform: scale(5);
    max-width: 100%;
    height: 100%;
    bottom: 150px;
    left: 0;
    z-index: 2;
    filter: blur(.05rem);
    }
    
    .d-2 { 
      position: absolute;
      transform: scale(4);
      max-width: 100%;
      height: 100%;
      top: 200px;
      left: 0;
      z-index: 1;
      filter: blur(.15rem);
    }
    
    .d-3 { 
      position: absolute;
      transform: scale(5);
      max-width: 100%;
      height: 100%;
      bottom: 150px;
      left: 0;
      z-index: 2;
      filter: blur(.05rem);
    }
    .d-4 { 
      position: absolute;
      transform: scale(4);
      max-width: 100%;
      height: 100%;
      top: 300px;
      left: 100px;
      z-index: 1;
      filter: blur(.1rem);
    }
    
    @media(min-width: 1020px) {
      .d-1 { 
        top: 300px;
        left: 200px;
        }
        
        .d-2 { 
          left: 550px;
          z-index: 1;
        }
    
      .d-3 {
        bottom: 450px;
        right: 300px;
      }
      .d-4 { 
        top: 250px;
        left: 1500px;
        }
    }
    
    // Card 
    
    .card-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 100%;
      max-width: 100%;
      z-index: 1;
      .card-container {
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 1200px;
        flex-wrap: wrap;
        z-index: 1;
        .card {
          width: 100%;
          height: 100%;
          min-height: 450px;
          max-height: 450px;
          height: auto;
          margin: 2rem;
          border-radius: 15px;
          background:rgba( 255, 255, 255, 0.10 );
          display: flex;
          backdrop-filter: blur(5px);
          &:hover, &:focus { 
            box-shadow: (rgba(black, 0.45) 0px 0px 20px 0px);
          }      
          .card-content {
            margin: 2rem 2rem 2rem;
            text-align: center;
            transition: 0.5;
            display: flex;
            flex-direction: column;
            .card-image { 
              width: 100%;
              height: 100%;
              z-index: 2;
              justify-content: space-between;
            }
            h2 { 
              justify-content: space-between;
            }
            .card-link {
              z-index: 50;
              opacity: 0;
              height: 100%;
              width: 100%;
              cursor: pointer;
            }
          }
        }
      }
    }
    
        
// Manipulations
    
     
    .card-split {
      display: flex;
      flex-direction: column;
    }
    
    @media (min-width: 50em) {
      .card-split {
          flex-direction: row;
      }
      .card-split > * {
          flex-basis: 100%;
      }
      .card-split > * + * {
        margin-left: 2em;
      }
    }
    
  
    
    .flex-center-vertical { 
      flex-direction: column;
    }
    
    // Animations & SVG 
    
  
    
    .branding-svg {
      width: 100%;
      height: 100%;
    }
    
    
    @keyframes bounce { 
      0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
      }
      40% {
        transform: translateY(-30px);
      }
      60% {
        transform: translateY(-15px);
      }
    }

