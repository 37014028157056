// Bouncing Arrow 

.scrollme {
    position: absolute;
    bottom: 0;
    left: 50%;
    right: 50%;
    align-items: center;
}
  
.arrow {
    text-align: center;
    margin: auto;
    align-items: center;
    color: $neutral--00;

}
.bounce {
    -moz-animation: bounce 2s infinite;
    -webkit-animation: bounce 2s infinite;
    animation: bounce 2s infinite;
}