
// Buttons 
  
.btn {
    padding: 1em 2em;
    color: white;
    background: rgba(255, 255, 255, 0.10);
    border-radius: 40px;
    text-align: center;
    text-decoration: none;
    font-weight: medium;
    cursor: pointer;
    font-size: .8em;
    transition : all 0.2s ease-in-out;
    &:hover, &:after { 
      transition : all 0.2s ease-in-out;
      background: rgba(255, 255, 255, 0.596);
      color: $black;
  }
}

.btn-cta { 
  background: $primary-color--500;
  &:hover {
    background: $primary-color--300;
  }
}

.btn-small { 
  font-size: .6em;
}

.btn-large { 
  font-size: 1em;
}

@media (max-width: 40em){
  .btn-large { 
    font-size: .8em;
  }
}