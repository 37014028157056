@use "sass:math";


@mixin bp($width, $direction: max) {
    @if $direction == min {
        @media screen and (min-width: $width) {
            @content;
        }
    } @else {
        @media screen and (max-width: $width) {
            @content;
        }
    }
}