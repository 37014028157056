
html { 
  box-sizing: border-box;
}

* { 
    margin: 0;
    padding: 0;
    overflow: visible;
    -webkit-overflow-scrolling: touch;
    box-sizing: inherit;
}

*,
*::before,
*::after {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    
}




body::-webkit-scrollbar {
  display: none;
}
body::-webkit-scrollbar-track { 
  display: none;
}

// Backgrounds
  
.wrapper {
  width: 100%; 
  height: 100%;
}

#vanta-canvas {
    max-width: 100%;
    height: 100%;
    object-fit: cover;
}



.bg {
  background-size: cover;
  max-width: 100%;
  height: 100%;
  padding: 15rem 1rem 15rem 1rem;
  margin: 0;
  overflow: hidden;
  z-index: 1;
}
// Media Queries 
  
  @media (min-width: 1020px) {
    .bg {
      background-size: cover;
      max-width: 100%;
      min-height: 100vh;
      padding: 10rem 1rem 10rem 1rem;
      margin: 0;
      overflow: hidden;
      z-index: 1;
     }
    
  }

  .bg-services { 
    max-width: 100%;
    height: 100%;
    padding: 5rem 1rem 5rem 1rem;
    margin: 0;
    overflow: hidden;
  }