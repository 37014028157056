body {
  font-family: $primary-font;
  margin: 0;
  padding: 0;
}


body,
h1,
h2,
h3 {
  font-family: $primary-font;
  margin: 0;
}

h1 {
  font-size: var(--fs-xl);
  font-weight: 700;
  text-transform: uppercase;
}

h2 {
  font-family: $primary-font;
  font-size: var(--fs-lg);
  font-weight: 600;
}

h3 {
  font-family: $primary-font;
  font-size: var(--fs-md);
  font-weight: 300;
}

p { 
  font-size: var(--fs-sm);
  font-weight: 300;
  font-family: $secondary-font;
  color: $neutral--300;
}

ul { 
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  text-decoration: none;
  font-family: $secondary-font;
  font-size: var(--fs-sm);
  font-weight: 600;
  text-transform: uppercase;
}