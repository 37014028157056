
.section { 
    min-width: 100%;
    min-height: 100%; 
}

.info-container {
  overflow: hidden;
  max-width: 55rem;
  min-height: 100%;
  padding: 2rem 0 2rem 0;
}

.container {
  width: min (90%, 70.5rem);
  overflow: hidden;
  max-width: 55rem;
  min-height: 100%;
}

.services-container {
  width: 100%;
  height: 100%;
  padding: 1.5rem 1rem 1.5rem 1rem ;
  overflow: hidden;
  @media(min-width: 40em) {
    padding: 15rem 1rem 15rem 1rem;
  }
}



.svg-container {
  width: 100%;
  height: 100%;
}

.browser-svg {
  width: 100%;
  height: 100%;
  overflow: visible;
  padding: 1rem;
  max-height: 300px;
}

@media(min-width: 600px){
  .browser-svg {
    max-height: 750px;
    padding: 2rem;
  }
}
.svg-content {
  width: 100%;
  height: 100%;
  overflow: visible;
  padding: 1rem;
  max-height: 300px;
}




.small-container { 
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding: 1.5rem 1rem 1.5rem 1rem;
}

.panel-container { 
  max-width: 50rem;
  padding-left: 1em;
  padding-right: 1em;
  padding-top: 4em;
  @media(min-width: 40em) { 
    padding: 2em;
  }
}

.center {
  margin: 0 auto;
  width: 100%;
  padding: 10rem 1rem;
  position: relative;  

  @media(min-width: 1020px){
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}

.mobile-padding-header { 
  padding: 0;
  @media( min-width: 40em) {
    padding-top: 5.5rem;
  padding-bottom: 3.5rem;
  }
}

.split {
  display: flex;
  flex-direction: column;
}

@media (min-width: 40em) {
  .split {
      flex-direction: row;
  }
  .split > * {
      flex-basis: 100%;
  }
  .split > * + * {
    margin-left: 2em;
  }
}

.split-reverse {
  display: flex;
  flex-direction: column-reverse;
}

@media (min-width: 40em) {
  .split-reverse {
      flex-direction: row;
  }
  .split-reverse > * {
      flex-basis: 100%;
  }
  .split-reverse > * + * {
    margin-right: 2em;
  }
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-center { 
  text-align: center;
}

// Color Selectors

.black {
  color: $black;
}

.white {
  color: $neutral--00;
}

.grey-ul {
  color: $neutral--100;
}

.grey-light {
  color: $neutral--200;
}
.grey-med { 
  color: $neutral--300;
}

.grey-dark {
  color: $neutral--800;
}

.primary-200 {
  color: $primary-color--200;
}

.primary-300 {
  color: $primary-color--300;
}
.primary-500 {
  color: $primary-color--500;
}
.primary-600 {
  color: $primary-color--600;
}
.primary-700 {
  color: $primary-color--700;
}

.neutral-200 { 
  color: $neutral--200;
}

// BG Selectors



.bg-black {
  background: $black;
}

.bg-transparent {
  background: transparent;
}

.bg-gray {
  background: $neutral--800;
}

.bg-ul {
  background: $neutral--100;
}