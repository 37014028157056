.landing {
  background-size: cover;
  max-width: 100%;
  height: 100vh;
  padding: 0;
  overflow: hidden;
  z-index: 1;
  background-color: transparent;
  &__wrapper { 
    max-width: 100%;
    height: 100%;
  }
}


.landing-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 1rem;
  justify-content: center;
  align-items: center;
  .landing-content { 
    width: 100%;
    .landing-dots { 
      padding-top: 0.5rem;
    }
    p {
      padding-bottom: 2rem;
    }
  }
}
.bp {
  padding-bottom: 2rem;
}
.tp { 
  padding-top: 2rem;
}


@media (min-width: 600px){
  .landing {
    height: 100%;
    padding: 4rem 5rem 4rem 5rem;
    .scrollme {
      display: none;
    }
  }
  .landing-container { 
    flex-direction: row;
    padding: 5rem 0 5rem 0;

    .landing-content { 
      flex: 50%;
      padding: 3rem 0 0 0;
      max-width: 50rem;
      p { 
        padding-bottom: 1rem;
      }
      .tp {
        padding: 0;

      }
      .bp { 
        padding: 0;
      }
    }
  }
}


.bg-info { 
  padding: 3rem 1.5rem 3rem 1.5rem;
}

@media(min-width: 600px) { 
  .bg-info { 
    padding: 2rem 1rem 2rem 1rem;
  }

}

.line {
  width: 100px;
  height: 3px;
  padding-bottom: .5rem;
}

