@use "sass:math";

.preloader {
    z-index: 500;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $neutral--800;
    overflow: hidden;
    font-size: 1rem;
}
 
.preloader_in {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 28em;
    height: 32em;
    margin: math.div(-32em, 2) 0 0 math.div(-28em, 2);
    opacity: 0;
 
    @include bp ($width) {
        font-size: 0.7vh;
    }
}
 
.preloader_svg {
    position: absolute;
    top: 0;
    left: 0;
 
    width: 100%;
    height: 100%;
}

.loader-ring {
    position: absolute;
    top: 95%;
    left: 50%;
}


/* Loader ring with 6 dots */
.loader-ring, .loader-ring:before, .loader-ring:after {
    display: inline-block;
    width: 15px;
    height: 40px;
    line-height: 80px;
    transform-origin: 8px 0px;
    color: $primary-color--500;
    font-size: 40px;
    font-family: "Times New Roman";
    position: absolute;
    z-index: 100000;
    content: '•';
}
.loader-ring:before, .loader-ring:after {
    opacity: 0;
    text-shadow: 12px -2px 0px $primary-color--500, -12px -2px 0px $primary-color--500;
    transform: rotateZ(0deg);
    animation: loading 4s cubic-bezier(.15,.65,.85,.35) infinite, loading-shadow 2s cubic-bezier(.25,.75,.35,1) infinite alternate, loading-opacity 1s infinite;
}
.loader-ring:after {
    animation-delay: 1.35s;
}
@keyframes loading {
    to {
        transform: rotateZ(360deg);
    }
}
@keyframes loading-shadow {
    from {
        text-shadow: 35px -15px 0px $primary-color--500, -35px -15px 0px $primary-color--500;
    }
}
@keyframes loading-opacity {
    from {
        opacity: 1;
    }
    to {
        opacity: 1;
    }
}
@media(max-width: 768px){
    .loader-ring {
        display: none;
        visibility: hidden;
    }
}