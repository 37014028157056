footer {
    padding: 1rem;
    .footer-col-3 {
        display: flex;
        justify-content: space-evenly;
        align-items: left;
        flex-direction: column;
        .footer-col { 
            padding-top: 2rem;
            text-align: left;
        }
    }
    a {
        text-decoration: none;
        font-size: var(--fs-sm);
        color: $neutral--300;
        -webkit-transition: -webkit-transform .3s ease;
        transition: -webkit-transform .3s ease;
        -o-transition: transform .3s ease;
        transition: transform .3s ease;
        &:hover {
            color: $primary-color--300;
            -webkit-transition: -webkit-transform .3s ease;
            transition: -webkit-transform .3s ease;
            -o-transition: transform .3s ease;
            transition: transform .3s ease;
        }   
    }
}


@media(min-width: 760px){
    footer {
        padding: 1rem;
        .footer-col-3 {
            display: flex;
            justify-content: space-evenly;
            align-items: left;
            flex-direction: row;
            .footer-col { 
                text-align: left;
                a {
                    text-decoration: none;
                    font-size: var(--fs-sm);
                    color: $neutral--300;
                    &:hover {
                        color: $primary-color--300;
    
                    }
                }
            }
        }
    }
}
    
